import React, { useState } from 'react';
import './mystyles.scss';
import Studio from '../sections/studio';
import { Navbar } from '../sections/navbar';
import Team from '../sections/team';
import Publications from '../sections/publications';
import { Contact } from '../sections/contacts';
import { Moodboard } from '../sections/moodboard';
import { graphql, useStaticQuery } from 'gatsby';
import { Language, LinkLocation } from '../mlva-types';
import { LanguageEnum } from '../hooks/useTranslation';
import { SEO } from '../components/seo';

interface AboutPageProps {
  location: LinkLocation;
}
const AboutPage = ({ location }: AboutPageProps) => {
  const data = useStaticQuery(graphql`
    query DisplaySections {
      publications: markdownRemark(
        frontmatter: { templateKey: { eq: "publications-section" } }
      ) {
        frontmatter {
          hidePublications
        }
      }
      moodboard: markdownRemark(
        frontmatter: { templateKey: { eq: "moodboard-section" } }
      ) {
        frontmatter {
          hideMoodboard
        }
      }
    }
  `);

  const hideMoodboard = data.moodboard.frontmatter.hideMoodboard;
  const hidePublications = data.publications.frontmatter.hidePublications;

  const [language, setLanguage] = useState<Language>(
    location?.state?.language || LanguageEnum.EN
  );

  const changeLanguage = () => {
    if (language == LanguageEnum.EN) setLanguage(LanguageEnum.PT);
    else setLanguage(LanguageEnum.EN);
  };

  return (
    <>
      <Navbar
        language={language}
        changeLanguage={changeLanguage}
        hideMoodboard={hideMoodboard}
        hidePublications={hidePublications}
      />
      <Studio language={language} />
      <Team language={language} />
      {!hideMoodboard && <Moodboard />}
      {!hidePublications && <Publications language={language} />}
      <Contact language={language} />
    </>
  );
};

export default AboutPage;

export const Head = () => <SEO title={'MLVA | About'} />;
