import React, { useState } from 'react';
import { useTranslation } from '../hooks/useTranslation';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Language, Partnership } from '../mlva-types';

export const Partnerships = ({
  partnerships,
  language,
}: {
  partnerships: Partnership[];
  language: Language;
}) => {
  const [showPartnerships, setShowPartnerships] = useState(false);
  const toggleShowPartnerships = () => {
    setShowPartnerships(!showPartnerships);
  };

  const PARTNERSHIPS_HEADER = useTranslation(
    'Partnerships',
    'Parceiros',
    language
  );

  return (
    <div className="columns is-mobile">
      <div className="column is-2-desktop is-2-tablet is-5-mobile">
        {PARTNERSHIPS_HEADER}
      </div>
      <div className="column">
        {partnerships?.map((partnership: Partnership, index: number) => {
          return (
            <div
              key={index}
              className="columns is-mobile is-multiline partnerships"
            >
              <div
                className={`column pr-0 partnerships-padding is-2-desktop is-4-tablet is-10-mobile slide-down${
                  showPartnerships ? ' active' : ''
                }`}
              >
                {partnership.link ? (
                  <a
                    href={partnership.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {partnership.company}
                    {partnership.company && partnership.person && ' | '}
                    {partnership.person}
                  </a>
                ) : (
                  `${partnership.company}${
                    partnership.company && partnership.person && ' | '
                  }${partnership.person}`
                )}
              </div>
              {index == 0 && (
                <div
                  className={`column is-1 is-unselectable has-text-centered${
                    showPartnerships ? ' icon-weight-stronger' : ''
                  }`}
                >
                  <a onClick={toggleShowPartnerships}>
                    {showPartnerships ? (
                      <FontAwesomeIcon icon={faMinus} size="2xs" />
                    ) : (
                      <FontAwesomeIcon icon={faPlus} size="2xs" />
                    )}
                  </a>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
