import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const useCopyOnClick = (text: string, className?: string) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(text);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 2000);
  };

  return (
    <span className={`tooltip${className ? ' ' + className : ''}`}>
      <a onClick={handleCopyClick}>{text}</a>
      <span className={`tooltiptext ${tooltipVisible ? 'visible' : ''}`}>
        <FontAwesomeIcon icon={faCopy} />
      </span>
    </span>
  );
};

export default useCopyOnClick;
