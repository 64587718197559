import React from 'react';
import { Column, MoodboardItem } from '../mlva-types';

const calculateMinHeight3Columns = (threeColumns: Column[]) => {
  const heightA = threeColumns[0].height;
  const heightB = threeColumns[1].height;
  const heightC = threeColumns[2].height;

  if (heightA <= heightB && heightA <= heightC) {
    return 0;
  } else if (heightB <= heightA && heightB <= heightC) {
    return 1;
  } else {
    return 2;
  }
};

export const useMoodboard = (
  items: MoodboardItem[],
  handleOnClick?: (link: string) => void
) => {
  const threeColumns: Column[] = [
    { height: 0, items: [] },
    { height: 0, items: [] },
    { height: 0, items: [] },
  ];
  const twoColumns: Column[] = [
    { height: 0, items: [] },
    { height: 0, items: [] },
  ];

  items.forEach((item: MoodboardItem, index: number) => {
    const threeColumnIndex = calculateMinHeight3Columns(threeColumns);
    const twoColumnIndex = twoColumns[0].height <= twoColumns[1].height ? 0 : 1;
    threeColumns[threeColumnIndex].items.push(item);
    threeColumns[threeColumnIndex].height += 350 * item.aspectRatio + 24;
    twoColumns[twoColumnIndex].items.push(item);
    twoColumns[twoColumnIndex].height += 450 * item.aspectRatio + 24;
  });

  return (
    <div className="columns">
      <div className="column is-1 is-hidden-touch" />
      <div className="column is-10-desktop">
        <div className="columns moodboard is-hidden-touch">
          {threeColumns?.map((column, columnIndex) => (
            <div className="column" key={columnIndex}>
              {column.items?.map((item, index) => (
                <div className="pt-5" key={index}>
                  {item.link && handleOnClick != undefined ? (
                    <a
                      onClick={() => handleOnClick(item.link)}
                      className="coverphoto-container"
                    >
                      {item.image}
                      <div className="coverphoto-caption has-text-centered">
                        {item.caption}
                      </div>
                    </a>
                  ) : (
                    item.image
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="columns moodboard is-hidden-desktop">
          {twoColumns?.map((column, columnIndex) => (
            <div
              className={`column ${columnIndex == 0 ? 'pl-0' : 'pr-0'}`}
              key={columnIndex}
            >
              {column.items?.map((item, index) => (
                <div className="pt-5" key={index}>
                  {item.link && handleOnClick ? (
                    <a
                      onClick={() => handleOnClick(item.link)}
                      className="coverphoto-container"
                    >
                      {item.image}
                      <div className="coverphoto-caption has-text-centered">
                        {item.caption}
                      </div>
                    </a>
                  ) : (
                    item.image
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="column is-1 is-hidden-touch" />
      </div>
    </div>
  );
};
