import React, { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { toggleHamburguerMenu } from '../utils/hamburguer-menu';
import { useIsDesktop } from '../hooks/useIsDesktop';
import { Language } from '../mlva-types';
import useScrollDetected, { DirectionEnum } from '../hooks/useDetectScroll';
import { useTranslation } from '../hooks/useTranslation';

const activeStyle = {
  fontWeight: '400',
};

interface NavbarProps {
  language: Language;
  changeLanguage: () => void;
  hidePublications: boolean;
  hideMoodboard: boolean;
}

export const Navbar = ({
  language,
  changeLanguage,
  hideMoodboard,
  hidePublications,
}: NavbarProps) => {
  const logoPath = useStaticQuery(graphql`
    query NavbarLogo {
      markdownRemark(frontmatter: { templateKey: { eq: "homepage-section" } }) {
        frontmatter {
          logo {
            publicURL
          }
        }
      }
    }
  `).markdownRemark.frontmatter.logo?.publicURL;

  const [ignoreScrollEventDetection, setIgnoreScrollEventDetection] =
    useState(false);
  const isDesktop = useIsDesktop();
  const scrollDetected = useScrollDetected(82, 10, ignoreScrollEventDetection);

  const closeHamburguerMenu = () => {
    if (!isDesktop) {
      setIgnoreScrollEventDetection(true);
      toggleHamburguerMenu();
    }
  };

  useEffect(() => {
    let timeoutId: any;
    if (ignoreScrollEventDetection) {
      timeoutId = setTimeout(() => {
        setIgnoreScrollEventDetection(false);
      }, 600);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [ignoreScrollEventDetection]);

  return (
    <nav
      className={`px-0-touch navbar is-spaced is-transparent is-fixed-top is-unselectable ${
        scrollDetected.direction == DirectionEnum.Down
          ? 'navbar-hide'
          : 'navbar-display'
      }`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link
          to="/"
          className="navbar-item px-4 py-0"
          state={{ language: language }}
        >
          <img src={logoPath} alt="navigate to homepage" id="navbar-logo" />
        </Link>
        <a
          role="button"
          className="navbar-burger mr-2"
          aria-label="menu"
          aria-expanded="false"
          data-target="mlva-navbar"
          onClick={toggleHamburguerMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="mlva-navbar" className="navbar-menu">
        <div className="navbar-end">
          <ScrollLink
            to="studio"
            className="navbar-item px-5"
            activeClass="active"
            activeStyle={activeStyle}
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onClick={closeHamburguerMenu}
            ignoreCancelEvents={true}
          >
            {useTranslation('STUDIO', 'ATELIER', language)}
          </ScrollLink>
          <ScrollLink
            to="team"
            className="navbar-item px-5"
            activeClass="active"
            activeStyle={activeStyle}
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onClick={closeHamburguerMenu}
            ignoreCancelEvents={true}
          >
            {useTranslation('TEAM', 'EQUIPA', language)}
          </ScrollLink>
          {!hideMoodboard && (
            <ScrollLink
              to="moodboard"
              className="navbar-item px-5"
              activeClass="active"
              activeStyle={activeStyle}
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
              onClick={closeHamburguerMenu}
              ignoreCancelEvents={true}
            >
              MOODBOARD
            </ScrollLink>
          )}
          {!hidePublications && (
            <ScrollLink
              to="publications"
              className="navbar-item px-5"
              activeClass="active"
              activeStyle={activeStyle}
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
              onClick={closeHamburguerMenu}
              ignoreCancelEvents={true}
            >
              {useTranslation('PUBLICATIONS', 'PUBLICAÇÕES', language)}
            </ScrollLink>
          )}
          <ScrollLink
            to="contact"
            className="navbar-item px-5"
            activeClass="active"
            activeStyle={activeStyle}
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onClick={closeHamburguerMenu}
            ignoreCancelEvents={true}
          >
            {useTranslation('CONTACT', 'CONTACTO', language)}
          </ScrollLink>
          <a
            className="navbar-item px-5"
            onClick={() => {
              changeLanguage();
            }}
          >
            <span className={language == 'Pt' ? 'has-text-weight-medium' : ''}>
              PT
            </span>
            /
            <span className={language == 'En' ? 'has-text-weight-medium' : ''}>
              EN
            </span>
          </a>
        </div>
      </div>
    </nav>
  );
};
