import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { graphql, useStaticQuery } from 'gatsby';
import useCopyOnClick from '../hooks/useCopyOnClick';
import { ContactContent, Language } from '../mlva-types';
import { isUserAgentMobile } from '../hooks/useIsDesktop';
import { useTranslation } from '../hooks/useTranslation';

interface ContactProps {
  language: Language;
}

export const Contact = ({ language }: ContactProps) => {
  const content: ContactContent = useStaticQuery(graphql`
    query ContactSection {
      markdownRemark(frontmatter: { templateKey: { eq: "contact-section" } }) {
        frontmatter {
          studioTitle
          address
          addressLink
          emails {
            address
          }
          phoneNumbers {
            number
          }
          instagram
          linkedin
        }
      }
    }
  `).markdownRemark.frontmatter;

  const CONTACT_HEADER = useTranslation('Contact', 'Contacto', language);

  const isTouch = isUserAgentMobile();

  return (
    <section id="contact" className="main-section section is-medium">
      <h1 className="has-text-weight-medium mb-5">{CONTACT_HEADER}</h1>
      <div className="block">
        <p className="has-text-weight-medium">{content.studioTitle}</p>
      </div>
      <div className="block">
        <div>
          <a
            href={content.addressLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {content.address}
          </a>
        </div>
        <div>
          {content.emails?.map((email: { address: string }, index: number) => {
            return (
              <React.Fragment key={index}>
                {index > 0 ? <span className="pr-2"> | </span> : <></>}
                {useCopyOnClick(email.address)}
              </React.Fragment>
            );
          })}
        </div>
        <div>
          {content.phoneNumbers?.map(
            (phone: { number: string }, index: number) => {
              return (
                <React.Fragment key={index}>
                  {index > 0 ? <span className="pr-2"> | </span> : <></>}
                  {isTouch && (
                    <a
                      className="is-hidden-tablet"
                      href={`tel:${phone.number}`}
                    >
                      {phone.number}
                    </a>
                  )}
                  {useCopyOnClick(
                    phone.number,
                    isTouch ? 'is-hidden-mobile' : undefined
                  )}
                </React.Fragment>
              );
            }
          )}
        </div>
      </div>
      <div className="block social-media-align">
        {content.instagram && (
          <a href={content.instagram} target="_blank" rel="noopener noreferrer">
            <span className="icon">
              <FontAwesomeIcon icon={faInstagram} />
            </span>
          </a>
        )}
        {content.linkedin && (
          <a href={content.linkedin} target="_blank" rel="noopener noreferrer">
            <span className="icon">
              <FontAwesomeIcon icon={faLinkedinIn} swapOpacity={true} />
            </span>
          </a>
        )}
      </div>
      <div className="block copyright-align">
        <div>
          <span className="icon">©</span>
          {new Date().getFullYear()} All Rights Reserved
        </div>
      </div>
    </section>
  );
};
