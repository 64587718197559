import React, { useState } from 'react';
import { useTranslation } from '../hooks/useTranslation';
import { graphql, useStaticQuery } from 'gatsby';
import { Partners } from './partners';
import { Collaborators } from './collaborators';
import { Partnerships } from './partnerships';
import { PastCollaborators } from './pastCollaborators';
import { Language, TeamContent } from '../mlva-types';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface TeamsProps {
  language: Language;
}
const Team = ({ language }: TeamsProps) => {
  const content: TeamContent = useStaticQuery(graphql`
    query TeamSection {
      markdownRemark(frontmatter: { templateKey: { eq: "team-section" } }) {
        frontmatter {
          collaborators {
            name
            photo {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1)
              }
            }
            description {
              en
              pt
            }
          }
          partners {
            name
            photo {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1)
              }
            }
            description {
              en
              pt
            }
          }
          pastCollaborators {
            name
          }
          partnerships {
            company
            link
            person
          }
          teamPhoto {
            childImageSharp {
              gatsbyImageData
            }
            extension
            relativePath
          }
        }
      }
    }
  `).markdownRemark.frontmatter;

  const TEAM_HEADER = useTranslation('Team', 'Equipa', language);

  const teamPhotoImage =
    content.teamPhoto &&
    content.teamPhoto.extension != 'gif' &&
    (getImage(content.teamPhoto) as IGatsbyImageData);

  const teamPhotoGif =
    content.teamPhoto &&
    content.teamPhoto.extension == 'gif' &&
    content.teamPhoto.relativePath;

  return (
    <section id="team" className="main-section section is-medium">
      {(teamPhotoImage || teamPhotoGif) && (
        <div className="team-photo-container is-hidden-touch fade-in-team">
          {teamPhotoImage && (
            <GatsbyImage
              image={teamPhotoImage}
              alt="photo of the studio team"
            />
          )}
          {teamPhotoGif && (
            <img src={'/img/' + teamPhotoGif} alt="photo of the studio team" />
          )}
        </div>
      )}
      <div className="columns">
        <div className="column is-12" id="studio-left-content">
          <div className="columns">
            <div className="column">
              <h1 className="has-text-weight-medium">{TEAM_HEADER}</h1>
            </div>
          </div>
          {content.partners && (
            <Partners partners={content.partners} language={language} />
          )}
          {content.collaborators && (
            <Collaborators
              collaborators={content.collaborators}
              language={language}
            />
          )}
          {content.partnerships && (
            <Partnerships
              partnerships={content.partnerships}
              language={language}
            />
          )}
          {content.pastCollaborators && (
            <PastCollaborators
              collaborators={content.pastCollaborators}
              language={language}
            />
          )}
        </div>
      </div>
      {(teamPhotoImage || teamPhotoGif) && (
        <div className="team-photo-container is-hidden-desktop">
          {teamPhotoImage && (
            <GatsbyImage
              image={teamPhotoImage}
              alt="photo of the studio team"
            />
          )}
          {teamPhotoGif && (
            <img src={'/img/' + teamPhotoGif} alt="photo of the studio team" />
          )}
        </div>
      )}
    </section>
  );
};

export default Team;
