import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from '../hooks/useTranslation';
import { Language, StudioContent } from '../mlva-types';

interface StudioProps {
  language: Language;
}

const Studio = ({ language }: StudioProps) => {
  const content: StudioContent = useStaticQuery(graphql`
    query StudioSection {
      markdownRemark(frontmatter: { templateKey: { eq: "studio-section" } }) {
        frontmatter {
          completedProjects
          inProgressProjects
          description {
            en
            pt
          }
          expertiseAreas {
            en
            pt
          }
        }
      }
    }
  `).markdownRemark.frontmatter;

  const [completedProjectsCounter, setCompletedProjectsCounter] = useState(0);
  const [inProgressProjectsCounter, setInProgressProjectsCounter] = useState(0);
  const [counter, setCounter] = useState(0);

  const STUDIO_HEADER = useTranslation('Studio', 'Atelier', language);

  useEffect(() => {
    setTimeout(() => {
      let continueRunning = false;
      if (completedProjectsCounter < content.completedProjects) {
        setCompletedProjectsCounter(completedProjectsCounter + 1);
        continueRunning = true;
      }
      if (inProgressProjectsCounter < content.inProgressProjects) {
        setInProgressProjectsCounter(inProgressProjectsCounter + 1);
        continueRunning = true;
      }
      if (continueRunning) {
        setCounter(counter + 1);
      }
    }, 50);
  }, [counter]);

  return (
    <section id="studio" className="main-section section is-medium">
      <h1 className="has-text-weight-medium mb-5">{STUDIO_HEADER}</h1>
      <div className="columns is-multiline">
        <div className="column is-half is-12-touch" id="studio-left-content">
          {content.description?.length > 0 &&
            content.description?.map((paragraph: any, index: number) => {
              return (
                <div key={index} className="block has-text-justified">
                  {useTranslation(paragraph.en, paragraph.pt, language)}
                </div>
              );
            })}
          <div className="block">
            {useTranslation('Areas of expertise', 'Áreas de atuação', language)}{' '}
          </div>
          {content.expertiseAreas?.length > 0 &&
            content.expertiseAreas?.map((area: any, index: number) => {
              return (
                <div key={index}>
                  {useTranslation(area.en, area.pt, language)}
                </div>
              );
            })}
        </div>
        <div className="column is-1 is-hidden-touch" />
        <div className="column is-5">
          <div className="block">
            <p className="primary-text">+ {completedProjectsCounter}</p>
            <p className="terciary-text">
              {useTranslation(
                'completed projects',
                'projetos completados',
                language
              )}
            </p>
          </div>
          <div className="block">
            <p className="primary-text">+ {inProgressProjectsCounter}</p>
            <p className="terciary-text">
              {useTranslation(
                'projects in progress',
                'projetos em curso',
                language
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Studio;
