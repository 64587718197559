import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from '../hooks/useTranslation';
import {
  Translation,
  Language,
  PublicationsContent,
  Publication,
} from '../mlva-types';
import { PublicationGroup } from '../components/publication-group';

interface PublicationsProps {
  language: Language;
}

const Publications = ({ language }: PublicationsProps) => {
  const content: PublicationsContent = useStaticQuery(graphql`
    query PublicationsSection {
      markdownRemark(
        frontmatter: { templateKey: { eq: "publications-section" } }
      ) {
        frontmatter {
          awards {
            en
            pt
          }
          press {
            expertiseArea {
              en
              pt
            }
            link
            location {
              en
              pt
            }
            publisher
            year
          }
          web {
            expertiseArea {
              en
              pt
            }
            link
            location {
              en
              pt
            }
            publisher
            year
          }
          exhibition {
            expertiseArea {
              en
              pt
            }
            link
            location {
              en
              pt
            }
            publisher
            year
          }
        }
      }
    }
  `).markdownRemark.frontmatter;

  const PUBLICATIONS_HEADER = useTranslation(
    'Publications',
    'Publicações',
    language
  );

  const groupedPress = content.press?.reduce<{ [key: string]: Publication[] }>(
    (result, press) => {
      const year = String(press.year);
      if (result[year]) {
        result[year].push(press);
      } else {
        result[year] = [press];
      }
      return result;
    },
    {}
  );

  const groupedWeb = content.web?.reduce<{ [key: string]: Publication[] }>(
    (result, web) => {
      const year = String(web.year);
      if (result[year]) {
        result[year].push(web);
      } else {
        result[year] = [web];
      }
      return result;
    },
    {}
  );

  const groupedExhibition = content.exhibition?.reduce<{
    [key: string]: Publication[];
  }>((result, exhibition) => {
    const year = String(exhibition.year);
    if (result[year]) {
      result[year].push(exhibition);
    } else {
      result[year] = [exhibition];
    }
    return result;
  }, {});

  return (
    <section id="publications" className="main-section section is-medium">
      <h1 className="has-text-weight-medium mb-5">{PUBLICATIONS_HEADER}</h1>
      {content.awards?.length > 0 && (
        <>
          <h2 className="has-text-weight-medium mb-2">
            {useTranslation('Awards', 'Prémios', language)}
          </h2>
          <div className="mb-5">
            {content.awards?.map((award: Translation, index: number) => {
              return (
                <div key={index}>
                  {useTranslation(award.en, award.pt, language)}
                </div>
              );
            })}
          </div>
        </>
      )}
      {groupedPress && Object.keys(groupedPress).length > 0 && (
        <h2 className="has-text-weight-medium mt-5 py-2">
          {useTranslation('Press', 'Imprensa', language)}
        </h2>
      )}
      {groupedPress && (
        <div className="columns">
          <PublicationGroup
            publications={groupedPress}
            language={language}
            linkBasePath={'/press/'}
          />
        </div>
      )}

      {groupedWeb && Object.keys(groupedWeb).length > 0 && (
        <h2 className="has-text-weight-medium mt-5 py-2">
          {useTranslation('Web', 'Web', language)}
        </h2>
      )}
      {groupedWeb && (
        <div className="columns">
          <PublicationGroup publications={groupedWeb} language={language} />
        </div>
      )}
      {groupedExhibition && Object.keys(groupedExhibition).length > 0 && (
        <h2 className="has-text-weight-medium mt-5 py-2">
          {useTranslation('Exhibition', 'Exposições', language)}
        </h2>
      )}
      {groupedExhibition && (
        <div className="columns">
          <PublicationGroup
            publications={groupedExhibition}
            language={language}
          />
        </div>
      )}
    </section>
  );
};

export default Publications;
