import React from 'react';
import { useTranslation } from '../hooks/useTranslation';
import { Language, Publication } from '../mlva-types';
import { Link } from 'gatsby';

interface PublicationProps {
  publications: { [key: string]: Publication[] };
  language: Language;
  linkBasePath?: string;
}
export const PublicationGroup = ({
  publications,
  language,
  linkBasePath,
}: PublicationProps) => {
  const publicationDetails = (publication: Publication) => {
    return (
      <>
        <span className="pr-3 py-1">{publication.publisher}</span>
        {publication?.expertiseArea && (
          <>
            <span>|</span>
            <span className="px-3">
              {useTranslation(
                publication.expertiseArea.en,
                publication.expertiseArea.pt,
                language
              )}
            </span>
          </>
        )}
        {publication?.location && (
          <>
            <span>|</span>
            <span className="px-3">
              {useTranslation(
                publication.location.en,
                publication.location.pt,
                language
              )}
            </span>
          </>
        )}
      </>
    );
  };

  const yearGroup = Object.entries(publications)?.map(
    ([year, entries], groupIndex) => {
      return (
        <React.Fragment key={groupIndex}>
          {entries?.map((entry: any, index: number) => (
            <div
              className={`${index > 0 ? 'is-invisible' : ''} pr-6`}
              key={index}
            >
              {year}
            </div>
          ))}
        </React.Fragment>
      );
    }
  );

  return (
    <>
      <div className="column is-narrow-tablet is-hidden-mobile pr-0">
        {yearGroup}
      </div>
      <div className="column">
        {Object.entries(publications)?.map(([year, entries], groupIndex) => {
          return (
            <React.Fragment key={groupIndex}>
              {entries?.map((publication: Publication, index: number) => (
                <React.Fragment key={index}>
                  <div
                    className={`is-hidden-tablet${
                      groupIndex > 0 && index === 0
                        ? ' publication-year-group'
                        : ''
                    }`}
                  >
                    <span className={`${index === 0 ? '' : 'is-hidden'}`}>
                      {publication.year}
                      <br />
                    </span>
                  </div>
                  <div className="py-0">
                    {publication.link && !linkBasePath && (
                      <a
                        href={publication.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={publication.year + publication.publisher + index}
                      >
                        {publicationDetails(publication)}
                      </a>
                    )}
                    {publication.link && linkBasePath && (
                      <Link to={linkBasePath + publication.link}>
                        {publicationDetails(publication)}
                      </Link>
                    )}
                    {!publication.link && (
                      <>{publicationDetails(publication)}</>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
