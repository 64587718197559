import React from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { useMoodboard } from '../hooks/useMoodboard';
import { MoodboardItem } from '../mlva-types';
export const Moodboard = () => {
  const data = useStaticQuery(graphql`
    query Moodboard {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "moodboard-section" } } }
      ) {
        edges {
          node {
            frontmatter {
              photos {
                photo {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const frontmatter = data.allMarkdownRemark.edges[0].node.frontmatter;

  const MOODBOARD_HEADER = 'Moodboard';

  const moodboardItems: MoodboardItem[] = frontmatter.photos?.map(
    (elem: any, index: number) => {
      const image = getImage(elem.photo) as IGatsbyImageData;
      return {
        image: (
          <GatsbyImage
            key={index}
            image={image}
            alt={'moodboard photograph - ' + index}
          />
        ),
        aspectRatio:
          (image?.height && image.width && image.height / image.width) || 1,
      } as MoodboardItem;
    }
  );

  return (
    <section id="moodboard" className="main-section section is-medium">
      <h1 className="has-text-weight-medium mb-5 moodboard-header">
        {MOODBOARD_HEADER}
      </h1>
      {moodboardItems && useMoodboard(moodboardItems)}
    </section>
  );
};
