import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { useTranslation } from '../hooks/useTranslation';
import { Link as ScrollLink } from 'react-scroll';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Partner, Language, Translation } from '../mlva-types';
import { isUserAgentMobile } from '../hooks/useIsDesktop';

export const Partners = ({
  language,
  partners,
}: {
  language: Language;
  partners: Partner[];
}) => {
  const PARTNERS_HEADER = useTranslation('Partners', 'Fundadores', language);

  const [partnerOnSpotlight, setPartnerOnSpotlight] = useState<Partner | null>(
    null
  );

  const isTouch = isUserAgentMobile();

  const handleSpotlightChange = (partner: Partner) => {
    if (partnerOnSpotlight == partner) {
      setPartnerOnSpotlight(null);
    } else {
      setPartnerOnSpotlight(partner);
    }
  };

  return (
    <div className="columns is-mobile is-multiline">
      <div className="column is-2-tablet is-5-mobile pb-0">
        <div>{PARTNERS_HEADER}</div>
      </div>
      {partners?.map((partner: Partner, index: number) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && (
              <div className="column is-2-tablet is-5-mobile pb-0" />
            )}
            <div className={`column ${index === 0 ? 'pb-0 pt-3' : 'py-0'}`}>
              <ScrollLink
                to="partner-spotlight"
                spy={true}
                smooth={true}
                offset={isTouch ? -140 : -366}
                delay={200}
                duration={500}
                onClick={() => handleSpotlightChange(partner)}
              >
                <div className="columns is-mobile my-0">
                  <div
                    className={`column py-0 pr-0 is-2-desktop is-4-tablet is-10-mobile${
                      partnerOnSpotlight == partner
                        ? ' has-text-weight-medium'
                        : ''
                    }`}
                  >
                    {partner.name}
                  </div>
                  <div
                    className={`column py-0 is-1 has-text-centered is-unselectable${
                      partnerOnSpotlight == partner
                        ? ' icon-weight-stronger'
                        : ''
                    }`}
                  >
                    {partnerOnSpotlight == partner ? (
                      <FontAwesomeIcon icon={faMinus} size="2xs" />
                    ) : (
                      <FontAwesomeIcon icon={faPlus} size="2xs" />
                    )}
                  </div>
                </div>
              </ScrollLink>
            </div>
            <section
              className={`column is-12 slide-down py-0 ${
                partnerOnSpotlight == partner ? 'active' : ''
              }`}
              id="partner-spotlight"
            >
              <div className="columns py-4">
                <div
                  className={`column is-4${
                    index < partners.length - 1 ? ' pb-5' : ''
                  }`}
                >
                  {partner.description?.map(
                    (paragraph: Translation, index: number) => {
                      return (
                        <div key={index} className="block has-text-justified">
                          {useTranslation(paragraph.en, paragraph.pt, language)}
                        </div>
                      );
                    }
                  )}
                  {partner.photo && (
                    <GatsbyImage
                      image={getImage(partner.photo) as IGatsbyImageData}
                      alt={partner.name}
                      className="partner-photo-container"
                    />
                  )}
                </div>
              </div>
            </section>
          </React.Fragment>
        );
      })}
    </div>
  );
};
