import React, { useState } from 'react';
import { useTranslation } from '../hooks/useTranslation';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collaborator, Language } from '../mlva-types';

export const PastCollaborators = ({
  collaborators,
  language,
}: {
  collaborators: Collaborator[];
  language: Language;
}) => {
  const [showPastCollaborators, setShowPastCollaborators] = useState(false);

  const togglePastCollaborators = () => {
    setShowPastCollaborators(!showPastCollaborators);
  };

  const PAST_COLLABORATORS_HEADER = useTranslation(
    'Past collaborators',
    'Colaboradores antigos',
    language
  );

  return (
    <div className="columns is-mobile has-text-grey">
      <div className="column is-2-desktop is-2-tablet is-5-mobile">
        {PAST_COLLABORATORS_HEADER}
      </div>
      <div className="column">
        {collaborators?.map((pastCollaborator: Collaborator, index: number) => {
          return (
            <div
              key={index}
              className="columns is-mobile is-multiline pastCollaborator"
            >
              <div
                className={`column pr-0 is-2-desktop is-4-tablet is-10-mobile slide-down${
                  showPastCollaborators ? ' active' : ''
                }`}
              >
                {pastCollaborator.name}
              </div>
              {index == 0 && (
                <div
                  className={`column is-1 is-unselectable has-text-centered${
                    showPastCollaborators ? ' icon-weight-stronger' : ''
                  }`}
                >
                  <a onClick={togglePastCollaborators}>
                    {showPastCollaborators ? (
                      <FontAwesomeIcon icon={faMinus} size="2xs" />
                    ) : (
                      <FontAwesomeIcon icon={faPlus} size="2xs" />
                    )}
                  </a>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
